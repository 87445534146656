<template>
  <v-container fluid class="" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Zones
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col md="10">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 75vh; position: relative"
        >
          <v-card
            flat
            class="custom-grey-border remove-border-radius mt-4"
            v-if="false"
          >
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Add/Edit Zone Territories
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <div class="d-flex justify-content-end">
                      <v-checkbox
                        v-if="false"
                        :loading="dataLoading"
                        class="mx-4"
                        v-model="allow_zones"
                        color="red white--text"
                        label="Show to Visits and Technicians"
                        @change="updateShowZoneSetting()"
                      />

                      <v-btn
                        depressed
                        small
                        class="mx-4 white--text"
                        v-on:click="zoneDialogOpen"
                        color="cyan"
                      >
                        <span class="font-size-16 font-weight-600">Create</span>
                      </v-btn>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <v-simple-table
                    class="simple-table"
                    fixed-header
                    :height="zoneList?.length > 0 ? 'auto' : '100px'"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="simple-table-th" width="50">#</th>
                          <th class="simple-table-th" width="500">Zone</th>
                          <th class="simple-table-th">Description</th>
                          <th class="simple-table-th" width="70">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="zoneList?.length > 0">
                        <tr
                          v-for="(jobs, index) in zoneList"
                          :key="index"
                          class="alternate-listing-row"
                        >
                          <td width="50" class="simple-table-td">
                            {{ index + 1 }}
                          </td>
                          <td
                            width="100"
                            class="simple-table-td text-capitalize"
                          >
                            <template v-if="jobs?.name">
                              <v-chip
                                small
                                :color="jobs.color"
                                class="ml-1 text-white mt-n1"
                                label
                                style="width: 6px !important"
                              >
                              </v-chip>
                              {{ jobs?.name }}
                            </template>
                            <em v-else class="text-muted"> no zone </em>
                          </td>
                          <td class="simple-table-td">
                            <template v-if="jobs?.description">{{
                              jobs?.description
                            }}</template>
                            <em v-else class="text-muted"> no description </em>
                          </td>

                          <td class="simple-table-td">
                            <v-icon
                              color="cyan"
                              size="20"
                              v-on:click="updatezonesType(jobs)"
                            >
                              mdi-pencil</v-icon
                            >
                            <v-icon
                              color="red"
                              class="ml-2"
                              size="20"
                              v-on:click="deleteZones(jobs)"
                            >
                              mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tr v-else>
                        <td colspan="5" class="py-4">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no zones at the moment.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card flat class="custom-grey-border remove-border-radius mt-4">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Add/Edit Zones
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <div class="d-flex justify-content-end">
                      <v-btn
                        depressed
                        small
                        class="white--text"
                        v-on:click="zoneDialogOpen"
                        color="cyan"
                      >
                        <span class="font-size-16 font-weight-600">Create</span>
                      </v-btn>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <v-simple-table
                    class="simple-table"
                    fixed-header
                    style="min-height: 200px"
                    :height="paginatedZoneList?.length > 0 ? 'auto' : '100px'"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="simple-table-th" width="50">#</th>
                          <th class="simple-table-th" width="500">Zone</th>
                          <th class="simple-table-th">Description</th>
                          <th class="simple-table-th" width="70">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="paginatedZoneList?.length > 0">
                        <tr
                          v-for="(jobs, index) in paginatedZoneList"
                          :key="index"
                          class="alternate-listing-row"
                        >
                          <td width="50" class="simple-table-td">
                            {{ (currentPage - 1) * itemsPerPage + index + 1 }}
                          </td>
                          <td
                            width="100"
                            class="simple-table-td text-capitalize"
                          >
                            <template v-if="jobs?.name">
                              <v-chip
                                small
                                :color="jobs.color"
                                class="ml-1 text-white mt-n1"
                                label
                                style="width: 6px !important"
                              >
                              </v-chip>
                              {{ jobs?.name }}
                            </template>
                            <em v-else class="text-muted"> no zone </em>
                          </td>
                          <td class="simple-table-td">
                            <template v-if="jobs?.description">
                              <span>
                                {{ truncate(jobs?.description, 50) }}
                                <v-btn
                                  v-if="jobs?.description?.length > 50"
                                  small
                                  text
                                  color="cyan"
                                  class="font-weight-700"
                                  @click="showDescription(jobs.description)"
                                  >Read More</v-btn
                                >
                              </span>
                            </template>
                            <em v-else class="text-muted"> no description </em>
                          </td>
                          <td class="simple-table-td">
                            <v-icon
                              color="cyan"
                              size="20"
                              v-on:click="updatezonesType(jobs)"
                            >
                              mdi-pencil</v-icon
                            >
                            <v-icon
                              color="red"
                              class="ml-2"
                              size="20"
                              v-on:click="deleteZones(jobs)"
                            >
                              mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tr v-else>
                        <td colspan="5" class="py-4">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no zones at the moment.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row
                justify="end"
                class="align-center pagination_btn listing-footer px-2"
              >
                <p class="m-0 listing-footer-count">
                  Showing {{ (currentPage - 1) * itemsPerPage + 1 }} to
                  {{ Math.min(currentPage * itemsPerPage, zoneList.length) }} of
                  {{ zoneList.length }} entries
                </p>
                <v-spacer></v-spacer>
                <v-pagination
                  v-model="currentPage"
                  :length="pageCount"
                  total-visible="7"
                  color="cyan"
                  @input="handlePageChange"
                ></v-pagination>
              </v-row>
            </v-card-text>
          </v-card>

          <Dialog :commonDialog="zoneDialog" :dialog-width="850">
            <template v-slot:title>
              <template v-if="zonePayload?.id > 0">Edit</template
              ><template v-else>Add</template> Zone Territory
            </template>
            <template v-slot:body>
              <v-container fluid>
                <v-form
                  ref="ticketCollecttionFormSkills"
                  v-model.trim="formValid"
                  lazy-validation
                  v-on:submit.stop.prevent="updateJobSettingZone"
                >
                  <v-row>
                    <v-col md="12">
                      <label class="my-auto fw-500 required mb-1">Title</label>
                      <TextField
                        id="quotation-title"
                        dense
                        filled
                        placeholder="Name"
                        solo
                        flat
                        counter="50"
                        v-model.trim="zonePayload.name"
                        color="cyan"
                        :maxlength="250"
                        :rules="[
                          validateRules.required(zonePayload.name, 'Name'),
                        ]"
                      />
                    </v-col>
                    <v-col md="6">
                      <label class="my-auto fw-500 required mb-1">Color</label>
                      <v-color-picker
                        elevation="10"
                        v-model.trim="zonePayload.color"
                        :swatches="swatches"
                        show-swatches
                        hide-canvas
                        hide-inputs
                        swatches-max-height="100"
                      ></v-color-picker>
                    </v-col>
                    <v-col md="6">
                      <label class="my-auto fw-500 mb-1">Description</label>
                      <TextAreaField
                        auto-grow
                        dense
                        filled
                        color="cyan"
                        v-model="zonePayload.description"
                        placeholder="Description"
                        solo
                        flat
                        row-height="20"
                        counter="250"
                      />
                    </v-col>
                    <v-col md="12" v-if="false">
                      <label class="my-auto fw-500 required mb-1"
                        >Select Team</label
                      >
                      <v-autocomplete
                        hide-details
                        v-model.trim="zonePayload.teams_id"
                        :items="teams"
                        dense
                        flat
                        filled
                        item-color="cyan"
                        color="cyan"
                        solo
                        item-value="id"
                        multiple
                        item-text="team_name"
                        placeholder="Select Team"
                        :rules="[
                          validateRules.required(
                            zonePayload.teams_id,
                            'Team Name'
                          ),
                        ]"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </template>
            <template v-slot:action>
              <v-btn
                depressed
                :loading="pageLoading"
                :disabled="pageLoading || !formValid"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="updateJobSettingData(is_exist)"
              >
                Save
              </v-btn>
              <v-btn
                depressed
                :disabled="pageLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="zoneDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
          <Dialog :common-dialog="confirm_dialog" :dialog-width="850">
            <template v-slot:title> Confirmation </template>
            <template v-slot:body>
              <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
                <v-container class="px-10" fluid>
                  <h3 class="font-weight-500 text-center">
                    Zone already exists in another transaction. Any updates will
                    be reflected in a new transaction.
                  </h3>
                </v-container>
              </v-form>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="confirm_loading"
                v-on:click="confirm_dialog = false"
                class="mx-2 custom-grey-border custom-bold-button"
              >
                No! Close
              </v-btn>
              <v-btn
                v-on:click="updateJobSettingZone()"
                :disabled="!formValid || confirm_loading"
                :loading="confirm_loading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
              >
                Yes! Continue
              </v-btn>
            </template>
          </Dialog>
          <DeleteDialog :delete-dialog="deletejobDialog">
            <template v-slot:title>Delete Confirmation</template>
            <template v-slot:text>
              <h3 class="font-size-18 font-weight-600 delete-text">
                Are you sure you want to delete this zone ?
              </h3>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="deleteJobLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="deletejobDialog = false"
                >No! Cancel</v-btn
              >
              <v-btn
                :disabled="deleteJobLoading"
                :loading="deleteJobLoading"
                color="red lighten-1 white--text"
                v-on:click="deleteJobTypeConfirm()"
                class="mx-2 custom-grey-border custom-bold-button"
                >Yes! Delete</v-btn
              >
            </template>
          </DeleteDialog>
          <Dialog :commonDialog="desc_dialog" :dialog-width="800">
            <template v-slot:title> Description </template>
            <template v-slot:body>
              <v-container fluid>
                <div v-html="fullDescription"></div>
              </v-container>
            </template>

            <template v-slot:action>
              <v-btn
                depressed
                :disabled="pageLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="desc_dialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";

export default {
  name: "profile-job-setting",
  mixins: [ValidationMixin],
  data() {
    return {
      deleteJobLoading: false,
      confirm_dialog: false,
      confirm_loading: false,
      zoneDialog: false,
      desc_dialog: false,
      fullDescription: "",
      zonePayload: {
        name: null,
        is_active: 1,
        color: null,
        description: null,
        teams_id: [],
      },
      locationPageLoading: false, // or your actual loading state variable
      itemsPerPage: 10,
      currentPage: 1,
      is_exist: 0,
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      zone_type_id: 0,
      deletejobDialog: false,
      teams: [],
      allow_zones: false,
      pageLoading: true,
      dataLoading: false,
      setting: {
        prefix: null,
        client_remark: null,
        next_number: null,
        number_length: null,
        buffer_time: null,
        terms_conditions: null,
        admin_note: null,
        client_note: null,
        job_type: [],
      },
      allow_service_form: false,
      allow_voice_note: false,
      allow_is_image_editor: false,
      allow_stamp_note: false,
      app_setting: [],
      break_types: [],
      zoneList: [],
      summaryCerts: {
        active: 0,
        inactive: 0,
      },
    };
  },
  components: {
    Dialog,
    DeleteDialog,
  },
  methods: {
    updatezonesType(param) {
      console.log(param);
      const _this = this;
      _this.is_exist = param.is_exist ? 1 : 0;
      _this.zoneDialog = true;

      setTimeout(function () {
        _this.zonePayload = {
          id: _this.lodash.toSafeInteger(param.id),
          name: param?.name ? param.name : null,
          color: param.color ? param.color : null,
          description: param.description ? param.description : null,
          is_active: param.is_active ? param.is_active : 1,
          teams_id: param.zone_teams.length ? param.zone_teams : [],
        };
      }, 500);
      //this.getServiceSetting();
    },
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    showDescription(description) {
      this.fullDescription = description;
      this.desc_dialog = true;
    },
    setSummary() {
      let param = this.zoneList;
      let act = 0;
      let inact = 0;
      for (let i = 0; i < param.length; i++) {
        if (
          param[i].is_active == "1" ||
          param[i].is_active == 1 ||
          param[i].is_active == true
        ) {
          act += 1;
        } else {
          inact += 1;
        }
      }
      this.summaryCerts.active = act;
      this.summaryCerts.inactive = inact;
    },
    getServiceSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/zones" })
        .then(({ data }) => {
          if (Array.isArray(data.zones)) {
            _this.zoneList = data?.zones;
          }
          _this.allow_zones = data?.show_zones;
          if (Array.isArray(data.teams)) {
            _this.teams = data?.teams;
          }
          //_this.setSummary();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    deleteZones(data) {
      this.zone_type_id = data.id;
      this.is_exist = data.is_exist ? 1 : 0;
      this.deletejobDialog = true;
    },
    deleteJobTypeConfirm() {
      const _this = this;
      _this.deleteJobLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: `setting/zone`,
          data: {
            id: this.zone_type_id,
            is_exist: this.is_exist,
          },
        })
        .then(() => {
          _this.deleteJobLoading = false;
          _this.deletejobDialog = false;
          _this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
          _this.deleteJobLoading = false;
        });
    },
    updateShowZoneSetting() {
      let _this = this;
      _this.dataLoading = true;
      _this.pageLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "setting/zones_show_setting",
          data: { allow_zones: _this.allow_zones },
        })
        .then(() => {
          _this.getServiceSetting();
          _this.confirm_dialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
          _this.pageLoading = false;
        });
    },

    updateJobSettingData(type) {
      if (type == 0) {
        this.updateJobSettingZone();
      } else {
        this.confirm_dialog = true;
      }
    },
    updateJobSettingZone() {
      const _this = this;
      const formErrors = _this.validateForm(
        _this.$refs.ticketCollecttionFormSkills
      );

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          _this.pageLoading = false;
        }
        return false;
      }

      if (this.zonePayload?.color?.hexa) {
        let colorCode = this.zonePayload?.color?.hexa
          ? this.zonePayload?.color?.hexa
          : null;
        this.zonePayload.color = colorCode;
      }
      _this.pageLoading = true;
      _this.dataLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "setting/zones",
          data: _this.zonePayload,

          // {
          //   zones: _this.zonePayload,
          //   allow_zones: this.allow_zones ? 1 : 0,
          // },
        })
        .then(() => {
          _this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
          _this.pageLoading = false;
          _this.zoneDialog = false;
        });
    },

    zoneDialogOpen() {
      this.zonePayload = {
        name: null,
        is_active: 1,
        color: null,
        description: null,
        teams_id: [],
      };
      this.zoneDialog = true;
    },

    createJobType() {
      this.zoneList.push({
        name: null,
        is_active: 1,
      });
      this.setSummary();
    },
    deleteJobType(index) {
      if (this.zoneList.length > 1) {
        this.zoneList.splice(index, 1);
      }
    },
    getJobSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job" })
        .then(({ data }) => {
          _this.setting = data;
          _this.app_setting = data.app_setting;
          _this.break_types = data.breaks;
          _this.allow_zones = data.allow_zones;
          // if (data.job_type) {
          //   _this.zoneList = data.job_type.map((row) => {
          //     return { text: row };
          //   });
          // }
          this.allow_service_form =
            data.allow_service_form && data.allow_service_form == 1
              ? true
              : false;
          this.allow_stamp_note =
            data.allow_stamp_note && data.allow_stamp_note == 1 ? true : false;
          this.allow_voice_note =
            data.allow_voice_note && data.allow_voice_note == 1 ? true : false;
          this.allow_is_image_editor =
            data.allow_is_image_editor && data.allow_is_image_editor == 1
              ? true
              : false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    handlePageChange(page) {
      this.ZonePageLoading = true;
      this.currentPage = page;
      setTimeout(() => {
        this.ZonePageLoading = false;
      }, 1000);
    },
  },
  mounted() {
    this.getServiceSetting();
  },
  computed: {
    pageCount() {
      return Math.ceil(this.zoneList.length / this.itemsPerPage);
    },
    paginatedZoneList() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.zoneList.slice(start, end);
    },
  },
};
</script>
