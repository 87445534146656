<template>
  <v-container fluid class="custom-fields-container">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Custom Fields
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>

    <v-card flat class="custom-grey-border remove-border-radius">
      <v-card-text class="pa-0">
        <div class="tab-sticky px-3 py-1 white">
          <v-btn-toggle
            v-model="fieldTabs"
            mandatory
            active-class="blue darken-4 white--text"
            @change="getFieldData()"
          >
            <v-btn
              v-for="(module, index) in gAllModule"
              :key="module + index"
              :value="module.value"
              style="min-height: 30px !important"
            >
              {{ module.text }} ({{
                gTotalCount[module.value] ? gTotalCount[module.value] : 0
              }})
            </v-btn>
          </v-btn-toggle>
        </div>
        <v-layout class="px-3 py-1 white">
          <TextEditable
            v-model.trim="form_title"
            :loading="pageLoading"
            @change="updateTitle"
          />
          <v-spacer></v-spacer>
          <div class="d-flex">
            <v-btn
              v-if="isOrderUpdate"
              small
              tile
              depressed
              class="text-white mr-3"
              color="blue darken-4"
              :disabled="pageLoading"
              :loading="pageLoading"
              @click="updateOrder"
            >
              <span class="font-size-16 font-weight-600">Update Order</span>
            </v-btn>
            <template v-if="fields.length">
              <v-btn
                small
                tile
                depressed
                outlined
                class="text-white mr-3"
                color="blue darken-4"
                :disabled="pageLoading"
                :loading="pageLoading"
                @click="isPreview = true"
                v-if="!isPreview"
              >
                <span class="font-size-16 font-weight-600">Preview Form</span>
              </v-btn>
              <v-btn
                v-if="isPreview"
                small
                tile
                depressed
                outlined
                class="text-white mr-3"
                color="red"
                :disabled="pageLoading"
                :loading="pageLoading"
                @click="isPreview = false"
              >
                <span class="font-size-16 font-weight-600"
                  >Close Preview Form</span
                >
              </v-btn>
            </template>
            <v-btn
              small
              depressed
              class="text-white"
              color="cyan"
              :disabled="pageLoading"
              :loading="pageLoading"
              @click="openAddDialog"
            >
              <span class="font-size-16 font-weight-600">Add Custom Field</span>
            </v-btn>
          </div>
        </v-layout>
        <v-tabs-items v-model="fieldTabs">
          <v-tab-item
            v-for="(module, index) in gAllModule"
            :key="module + index"
            :value="`${module.value}`"
          >
            <perfect-scrollbar
              v-if="fieldTabs == module.value"
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow h-100"
              style="position: relative; max-height: calc(100vh - 150px)"
            >
              <template v-if="!pageLoading">
                <template v-if="fields.length">
                  <template v-if="!isPreview">
                    <v-simple-table class="pb-13">
                      <template v-slot:default>
                        <thead style="position: sticky; top: 0px">
                          <tr class="blue lighten-4">
                            <th class="px-1" width="20px"></th>
                            <th class="simple-table-th">Field Name</th>
                            <th class="simple-table-th">Field TYPE</th>
                            <th class="simple-table-th" width="200px">Width</th>
                            <th class="simple-table-th" width="200px">
                              Mandatory
                            </th>
                            <th class="simple-table-th" width="200px">
                              Status
                            </th>
                            <th class="simple-table-th" width="80px"></th>
                          </tr>
                        </thead>
                        <Draggable
                          tag="tbody"
                          v-model="fields"
                          class="input-options-drag-group"
                          draggable=".input-options-drag"
                          handle=".drag-handle"
                          ghost-class="input-options-ghost"
                          animation="200"
                          group="input-options-drag"
                          v-on:start="drag = true"
                          v-on:end="drag = false"
                          v-on:change="change_drag"
                        >
                          <transition-group
                            tag="tr"
                            type="transition"
                            :name="!drag ? 'flip-list' : null"
                            v-for="(field, index) in fields"
                            :key="'field' + index"
                            class="input-options-drag"
                          >
                            <td :key="`drag-handle-${index}`" class="px-1">
                              <v-btn
                                min-width="20px"
                                width="20px"
                                icon
                                color="blue darken-4"
                                class="cursor-drag drag-handle"
                              >
                                <v-icon>mdi-drag</v-icon>
                              </v-btn>
                            </td>
                            <td
                              :key="`label-${index}`"
                              class="simple-table-td px-4"
                            >
                              {{ field.label }}
                            </td>
                            <td
                              :key="`field-type-${index}`"
                              class="simple-table-td px-4"
                            >
                              <v-chip
                                small
                                outlined
                                label
                                class="text-capitalize"
                              >
                                {{ field.field_type }}
                              </v-chip>
                            </td>
                            <td
                              :key="`column-${index}`"
                              class="simple-table-td px-4"
                            >
                              <v-chip
                                small
                                width="60"
                                label
                                :color="getColumn(field.column)['color']"
                              >
                                {{ getColumn(field.column)["width"] }}
                              </v-chip>
                              <v-icon
                                v-if="field.start_with_new_line"
                                size="18"
                                color="red"
                                >mdi-format-horizontal-align-left</v-icon
                              >
                            </td>
                            <td
                              :key="`mandatory-${index}`"
                              class="simple-table-td px-4"
                            >
                              <v-chip
                                v-if="field.is_mandatory"
                                small
                                outlined
                                label
                                color="red"
                              >
                                YES
                              </v-chip>
                              <v-chip v-else small outlined label color="grey">
                                NO
                              </v-chip>
                            </td>
                            <td
                              :key="`status-${index}`"
                              class="simple-table-td px-4"
                            >
                              <StatusField
                                :items="[
                                  {
                                    text: 'Active',
                                    status_color: 'green',
                                    value: 'active',
                                  },
                                  {
                                    text: 'In-Active',
                                    status_color: 'red',
                                    value: 'in-active',
                                  },
                                ]"
                                v-model="field.status"
                                @change="
                                  fieldAction('status-update', field, index)
                                "
                                small
                                label
                                outlined
                              />
                            </td>
                            <td
                              :key="`action-${index}`"
                              class="simple-table-td px-4"
                            >
                              <v-menu bottom left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    text
                                    color="blue darken-4"
                                  >
                                    <v-icon
                                      >mdi-dots-horizontal-circle-outline</v-icon
                                    >
                                  </v-btn>
                                </template>
                                <v-list class="py-0" min-width="150px">
                                  <v-list-item
                                    link
                                    class="border-bottom"
                                    v-on:click="
                                      fieldAction('edit', field, index)
                                    "
                                  >
                                    <v-list-item-icon class="my-2 mr-0">
                                      <v-icon size="18">mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title class="font-weight-600">
                                      Edit
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item
                                    link
                                    class="border-bottom red--text"
                                    v-on:click="
                                      fieldAction('delete', field, index)
                                    "
                                  >
                                    <v-list-item-icon class="my-2 mr-0">
                                      <v-icon size="18" color="red"
                                        >mdi-delete</v-icon
                                      >
                                    </v-list-item-icon>
                                    <v-list-item-title class="font-weight-600">
                                      Delete
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                          </transition-group>
                        </Draggable>
                      </template>
                    </v-simple-table>
                  </template>
                  <template v-else>
                    <div class="pb-13">
                      <CustomForm :module="fieldTabs" />
                    </div>
                  </template>
                </template>
                <div
                  v-else
                  class="row-not-found text-center font-weight-500 font-size-16 py-10"
                >
                  <img
                    width="50"
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no custom field at the moment.
                </div>
              </template>
              <template v-else>
                <div class="py-15 col-md-6 mx-auto">
                  <v-progress-linear
                    indeterminate
                    color="orange"
                    height="6"
                  ></v-progress-linear>
                  <div class="text-center text-h6 mt-3">Loading...</div>
                </div>
              </template>
            </perfect-scrollbar>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <!-- Custom Form For Testing -->
    <Dialog
      v-if="create_or_update_dialog"
      :dialog="create_or_update_dialog"
      :dialog-width="getDialogWidth"
    >
      <template v-slot:title>
        {{ update_id ? "Update" : "Create" }} Custom Field
      </template>
      <template v-slot:body>
        <CreateCustomField
          :update-id="update_id"
          :module="fieldTabs"
          :order="fields.length"
          ref="custom-field-form-ref"
          @loading="($event) => (pageLoading = $event)"
          @success="onSuccess()"
        />
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          tile
          :disabled="pageLoading"
          @click="
            create_or_update_dialog = false;
            update_id = 0;
          "
        >
          Cancel
        </v-btn>
        <v-btn
          class="white--text"
          depressed
          color="blue darken-4"
          tile
          :loading="pageLoading"
          @click="createOrUpdate"
        >
          {{ update_id ? "Update" : "Create" }}
        </v-btn>
      </template>
    </Dialog>
    <DeleteTemplate
      type="Field"
      :deleteUrl="delete_url"
      :deleteText="delete_text"
      :deleteDialog="delete_dialog"
      v-on:close="delete_dialog = false"
      v-on:success="
        () => {
          delete_dialog = false;
          getFieldData();
        }
      "
    ></DeleteTemplate>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import CustomForm from "@/view/pages/custom-field-v2/CustomForm.vue";
import TextEditable from "@/view/pages/custom-field-v2/field-properties/c-text-editable.vue";
import Draggable from "vuedraggable";
import Dialog from "@/view/components/Dialog.vue";
import CreateCustomField from "./CreateCustomField.vue";
import { GET, POST } from "@/core/services/store/request.module";
import {
  UPDATE_TITLE,
  GET_FIELD_DATA,
  SET_ALL_MODULE_TITLE,
} from "@/core/services/store/custom.fields.module";
import StatusField from "@/view/components/app-component/StatusField.vue";
import DeleteTemplate from "@/view/components/app-component/DeleteTemplate.vue";
import { map } from "lodash";
import { SuccessEventBus } from "@/core/lib/message.lib";
export default {
  name: "CustomFieldV2",
  data() {
    return {
      isPreview: false,
      fields: [],
      create_or_update_dialog: false,
      pageLoading: false,
      // total_count: {},
      form_title: "",

      drag: false,
      isOrderUpdate: false,
      timeout: null,

      update_id: 0,
      fieldTabs: "customer",
      moduleList: [],

      delete_url: null,
      delete_text: null,
      delete_dialog: false,
    };
  },
  watch: {
    gFormTitle: {
      immediate: true,
      handler(param) {
        this.form_title = param;
      },
    },
    gFormFields: {
      handler(param) {
        this.fields = param;
      },
    },
  },
  methods: {
    openAddDialog() {
      this.create_or_update_dialog = true;
    },
    change_drag() {
      this.isOrderUpdate = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const updated_order = map(this.fields, (row, index) => {
          return { ...row, order: index };
        });
        this.fields = updated_order;
      }, 300);
    },
    updateOrder() {
      const _this = this;
      _this.isOrderUpdate = false;
      const updated_field = map(this.fields, (row) => {
        return { id: row.custom_id, order: row.order };
      });
      _this.$store
        .dispatch(POST, {
          url: "module/custom-fields-order-update",
          data: {
            module_type: this.fieldTabs,
            custom_fields: updated_field,
          },
        })
        .then(() => {
          SuccessEventBus.$emit(
            "update:success",
            "Field order updated successfully."
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateStatus(id, status) {
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: `module/custom-fields-status-update/${id}`,
          data: {
            module_type: this.fieldTabs,
            status: status,
          },
        })
        .then(() => {
          SuccessEventBus.$emit(
            "update:success",
            "Field Status updated successfully."
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createOrUpdate() {
      const custom_field = this.$refs["custom-field-form-ref"];
      custom_field.createOrUpdate();
    },
    onSuccess() {
      this.create_or_update_dialog = false;
      this.update_id = 0;
      this.getFieldData();
    },
    getFieldData() {
      const _this = this;
      _this.isOrderUpdate = false;
      _this.isPreview = false;
      _this.pageLoading = true;

      _this.$store.dispatch(GET_FIELD_DATA, this.fieldTabs).finally(() => {
        this.pageLoading = false;
      });
      // _this.$store
      //   .dispatch(GET, { url: "module/custom-fields/" + this.fieldTabs })
      //   .then(({ data }) => {
      //     this.total_count = data.count;
      //     this.form_title = data.module.title;
      //     this.fields = data.rows;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   })
      //   .finally(() => {
      //     this.pageLoading = false;
      //   });
    },
    fieldAction(type, data) {
      switch (type) {
        case "edit":
          this.update_id = data.custom_id;
          this.create_or_update_dialog = true;
          break;
        case "status-update":
          this.updateStatus(data.custom_id, data.status);
          break;
        case "delete":
          this.delete_text = data.label;
          this.delete_url = `module/custom-fields/${data.custom_id}`;
          this.delete_dialog = true;
          break;
      }
    },
    getColumn(col) {
      switch (Number(col)) {
        case 4:
          return { width: "33%", color: "blue" };
        case 6:
          return { width: "50%", color: "orange" };
        case 12:
          return { width: "100%", color: "green" };
      }
    },
    updateTitle(e) {
      this.$store
        .dispatch(UPDATE_TITLE, { module: this.fieldTabs, title: e })
        .then(() => {
          let _title = this.gModuleTitle;
          _title[this.fieldTabs] = e;
          this.$store.commit(SET_ALL_MODULE_TITLE, _title);
        });
    },
    getModules() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, { url: "module/all-modules" })
        .then(({ data }) => {
          const _list = data.map((row) => {
            return {
              ...row,
              text: row.module,
              value: row.module,
            };
          });
          this.moduleList = _list;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    async init() {
      //await this.getModules();
      this.moduleList = this.gAllModule;
      await this.getFieldData();
    },
  },
  computed: {
    ...mapGetters([
      "gLoading",
      "gTotalCount",
      "gFormTitle",
      "gFormFields",
      "gAllModule",
      "gModuleTitle",
    ]),
    getDialogWidth() {
      if (window.innerWidth > 1366) {
        return 1024;
      }
      return window.innerWidth;
    },
  },
  mounted() {
    this.init();
  },
  components: {
    Dialog,
    CreateCustomField,
    StatusField,
    DeleteTemplate,
    Draggable,
    CustomForm,
    TextEditable,
  },
};
</script>
