<template>
  <div class="d-inline-flex align-center">
    <template v-if="isLoading"> </template>
    <template v-else>
      <h3
        class="mb-0 px-2 editable-element"
        :class="{
          focused: isEditable,
        }"
        v-bind="{
          ...$attrs,
        }"
        :contenteditable="isEditable"
        @blur="onBlue"
        @input="onInput"
        v-text="editableText"
      ></h3>
      <h3 class="my-0 red--text" v-if="error">{{ error }}</h3>
      <v-icon
        v-if="!isEditable"
        size="18"
        color="blue"
        class="cursor-pointer ml-2"
        @click="makeEditable"
        >mdi-pencil</v-icon
      >
    </template>
  </div>
</template>
<script>
export default {
  name: "text-editable",
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editableText: null,
      isEditable: false,
      error: null,
      isEdited: false,
    };
  },
  watch: {
    value(param) {
      this.editableText = param;
      if (!param) {
        this.error = "Untitled form";
      } else {
        this.error = null;
      }
      // this.$emit('change',param)
    },
  },
  methods: {
    makeEditable() {
      this.isEditable = true;
      const elem = document.querySelector(".editable-element");
      if (elem) {
        this.$nextTick(() => {
          elem.focus();
        });
      }
    },
    onInput(e) {
      const value = e.target.innerText;
      if (value.trim()) {
        this.error = null;
      } else {
        this.isEditable = true;
        this.error = "Untitled form";
        this.$nextTick(() => {
          e.target.focus();
        });
      }
      this.isEdited = true;
    },
    onBlue(e) {
      this.isEditable = false;
      let value = e.target.innerText;
      if (this.isEdited) {
        this.$emit("change", value.trim());
      }
      this.isEdited = false;
    },
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  mounted() {
    if (this.value) {
      this.editableText = this.value;
    } else {
      this.error = "Untitled form";
    }
  },
};
</script>
<style lang="scss">
.focused {
  border: 1px solid;
  outline: none;
  border-radius: 4px;
}
</style>
