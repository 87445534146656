<template>
  <div class="container--fuild" v-if="getPermission('setting:view')">
    <v-card
      flat
      class="custom-setting-height custom-grey-border remove-border-radius"
    >
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Quotation #
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <v-col md="6" class="text-right py-0">
              <v-btn
                depressed
                small
                class="text-white"
                color="cyan"
                v-on:click="updateQuotationSetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-title>
      <v-card-text
        class="p-6 font-size-16"
        :class="{ 'pointer-events-none': !getPermission('setting:update') }"
      >
        <v-row>
          <v-col md="2" class="pb-4 col-lg-2">
            <label>Prefix</label>
            <v-text-field
              v-model.trim="setting.prefix"
              dense
              filled
              placeholder="Prefix"
              solo
              flat
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="2" class="pb-4 col-lg-2">
            <label>Next Number</label>
            <v-text-field
              v-model.trim="setting.next_number"
              dense
              filled
              placeholder="Next Number"
              solo
              flat
              type="number"
              min="0"
              max="999"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="2" class="pb-4 col-lg-2">
            <label>Number Length</label>
            <v-text-field
              v-model.trim="setting.number_length"
              dense
              filled
              placeholder="Number Length"
              solo
              flat
              type="number"
              min="0"
              max="15"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="cyan"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      flat
      class="custom-grey-border remove-border-radius mt-4"
      v-if="false"
    >
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="5" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Quotation Terms &amp; Conditions
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <v-col md="7" class="text-right py-0">
              <v-btn
                depressed
                small
                class="text-white"
                color="cyan"
                v-on:click="updateQuotationSetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-title>
      <v-card-text
        class="p-6 font-size-16"
        :class="{ 'pointer-events-none': !getPermission('setting:update') }"
      >
        <v-row>
          <v-col md="12" class="pb-4">
            <editor
              v-model="setting.terms_conditions"
              :disabled="pageLoading"
              :loading="pageLoading"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  name: "profile-quotation-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        quotation_open_days: null,
        terms_conditions: null,
        admin_note: null,
        client_note: null,
      },
    };
  },
  components: {
    editor: TinyMCE,
  },
  methods: {
    getQuotationSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/quotation" })
        .then(({ data }) => {
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateQuotationSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/quotation", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getQuotationSetting();
  },
};
</script>
