<template>
  <div>
    <Draggable
      tag="div"
      v-model="inputOptions"
      class="input-options-drag-group"
      draggable=".input-options-drag"
      handle=".drag-handle"
      ghost-class="input-options-ghost"
      animation="200"
      group="input-options-drag"
      v-on:start="drag = true"
      v-on:end="drag = false"
      v-on:change="change_drag"
    >
      <transition-group
        tag="div"
        type="transition"
        :name="!drag ? 'flip-list' : null"
        v-for="(row, index) in inputOptions"
        class="input-options-drag"
        :key="`input-options-drag-${index}`"
      >
        <v-layout class="py-1" :key="`input-options-element-${index}`">
          <div class="drag-handle">
            <v-btn min-width="30" width="30" height="32" icon>
              <v-icon>mdi-drag</v-icon>
            </v-btn>
          </div>
          <div style="flex-grow: 1">
            <TextField
              dense
              filled
              solo
              flat
              :placeholder="`Options-${index + 1}`"
              hide-details
              color="cyan"
              :counter="50"
              :rules="[
                validateRules.required(row.value, `Options-${index + 1}`),
              ]"
              v-model.trim="row.value"
              @input="emitOptions()"
            />
          </div>
          <div>
            <v-btn
              min-width="30"
              width="30"
              height="32"
              icon
              color="red"
              :disabled="inputOptions.length == 1 ? true : false"
              @click="removeOptions(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-layout>
      </transition-group>
    </Draggable>

    <div class="text-right mt-3">
      <v-btn
        class="white--text"
        color="blue darken-4"
        depressed
        style="margin-right: 30px"
        @click="addOptions()"
      >
        <v-icon>mdi-plus</v-icon>
        Add Options
      </v-btn>
    </div>
  </div>
</template>
<script>
import { cloneDeep, map } from "lodash";
import Draggable from "vuedraggable";
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  name: "Options",
  mixins: [ValidationMixin],
  data() {
    return {
      drag: true,
      inputOptions: [],
      timeout: null,
    };
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(param) {
        this.inputOptions = cloneDeep(param);
      },
    },
  },
  methods: {
    emitOptions() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit("input", [...this.inputOptions]);
      }, 300);
    },
    change_drag() {
      this.setOrder();
    },
    setOrder() {
      const updateOptions = map(this.inputOptions, (row, index) => {
        return { ...row, order: index + 1 };
      });
      this.inputOptions = updateOptions;
      this.emitOptions();
    },
    addOptions() {
      this.inputOptions.push({
        order: 0,
        value: null,
      });
      this.setOrder();
    },
    removeOptions(index) {
      this.inputOptions.splice(index, 1);
      this.setOrder();
    },
  },
  components: {
    Draggable,
  },
};
</script>
<style lang="scss" scoped>
.drag-handle {
  .v-btn {
    cursor: move;
  }
}
</style>
