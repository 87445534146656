<template>
  <div
    class="container container--fluid width-100"
    v-if="getPermission('setting:view')"
  >
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Invoice
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col md="10">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 85vh; position: relative"
        >
          <v-card
            flat
            class="custom-setting-height custom-grey-border remove-border-radius"
          >
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="6" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Invoice #
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="6" class="text-right py-0">
                    <v-btn
                      depressed
                      small
                      class="text-white"
                      color="cyan"
                      v-on:click="updateInvoiceSetting"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                    >
                      <span class="font-size-16 font-weight-600">Update</span>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="2" class="pb-4">
                  <label>Prefix</label>
                  <v-text-field
                    v-model.trim="setting.prefix"
                    dense
                    filled
                    placeholder="Prefix"
                    solo
                    flat
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col md="2" class="pb-4">
                  <label>Next Number</label>
                  <v-text-field
                    v-model.trim="setting.next_number"
                    dense
                    filled
                    placeholder="Next Number"
                    solo
                    flat
                    type="number"
                    min="0"
                    max="999"
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col md="2" class="pb-4">
                  <label>Number Length</label>
                  <v-text-field
                    v-model.trim="setting.number_length"
                    dense
                    filled
                    placeholder="Number Length"
                    solo
                    flat
                    type="number"
                    min="0"
                    max="15"
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            flat
            class="custom-setting-height custom-grey-border remove-border-radius mt-4"
          >
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="6" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Payment method
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="6" class="text-right py-0">
                    <v-btn
                      depressed
                      small
                      class="text-white"
                      color="cyan"
                      v-on:click="updateInvoiceSetting"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                    >
                      <span class="font-size-16 font-weight-600">Update</span>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <table class="width-100 mt-0">
                    <tbody>
                      <tr>
                        <v-switch
                          v-model="setting.is_invoice_partial_payment_allowed"
                          class="mt-0"
                          inset
                          label="Is partial payment allowed"
                          color="green"
                        ></v-switch>
                      </tr>
                      <tr>
                        <v-switch
                          v-model="setting.is_allowed_recur_invoice"
                          class="mt-0"
                          inset
                          label="Recurring Invoice allowed"
                          color="green"
                        ></v-switch>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div class="mt-4">
            <InvoiceLocking module="invoice"></InvoiceLocking>
          </div>
          <div class="mt-4">
            <note module="invoice"></note>
          </div>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import Note from "@/view/pages/profile/Setting/Note";
import InvoiceLocking from "@/view/pages/profile/Setting/InvoiceLocking";
export default {
  name: "profile-invoice-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        terms_conditions: null,
        admin_note: null,
        client_note: null,
        is_invoice_partial_payment_allowed: true,
        is_allowed_recur_invoice: true,
      },
    };
  },
  components: {
    Note,
    InvoiceLocking,
  },
  methods: {
    getInvoiceSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/invoice" })
        .then(({ data }) => {
          _this.setting = data;
          this.setting.is_invoice_partial_payment_allowed =
            data.is_invoice_partial_payment_allowed;
          this.setting.is_allowed_recur_invoice = data.is_allowed_recur_invoice;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateInvoiceSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/invoice", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getInvoiceSetting();
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getInvoiceSetting();
  },
};
</script>
