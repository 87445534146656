<template>
  <div class="container--fluid width-100" v-if="getPermission('setting:view')">
    <v-card
      flat
      class="custom-setting-height custom-grey-border remove-border-radius"
    >
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Visit #
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <v-col md="6" class="text-right py-0">
              <v-btn
                depressed
                small
                class="text-white"
                color="cyan"
                v-on:click="updateJobSetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-title>
      <v-card-text
        class="p-6 font-size-16"
        :class="{ 'pointer-events-none': !getPermission('setting:update') }"
      >
        <v-row>
          <v-col md="2" class="pb-4">
            <label>Prefix</label>
            <v-text-field
              v-model.trim="setting.prefix"
              dense
              filled
              placeholder="Prefix"
              solo
              flat
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="2" class="pb-4">
            <label>Next Number</label>
            <v-text-field
              v-model.trim="setting.next_number"
              dense
              filled
              placeholder="Next Number"
              solo
              flat
              type="number"
              min="0"
              max="999"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="2" class="pb-4">
            <label>Number Length</label>
            <v-text-field
              v-model.trim="setting.number_length"
              dense
              filled
              placeholder="Number Length"
              solo
              flat
              type="number"
              min="0"
              max="15"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col v-if="false" md="6" sm="6" class="pb-4 col-lg-6">
            <label
              >Buffer Time (in minutes)
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    dense
                    style="position: absolute; top: 10px; margin-left: 3px"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span
                  >You can't assign Job to particular Engineer/Technician
                  before<br />
                  or after for this buffer time of already assigned Job to
                  him.<br />
                  e.g. For Engineer A Job assigned from 10 AM to 11 AM &amp;
                  you<br />
                  kept Buffer time as 30 minutes, then in this case you can
                  not<br />
                  assign another Job to him between 9:30 AM till 11:30 AM</span
                >
              </v-tooltip>
            </label>
            <v-text-field
              v-model.trim="setting.buffer_time"
              dense
              filled
              placeholder="Buffer Time (in minutes)"
              solo
              flat
              type="number"
              min="10"
              max="1440"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="cyan"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
// import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  name: "profile-job-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        client_remark: null,
        next_number: null,
        number_length: null,
        buffer_time: null,
        terms_conditions: null,
        admin_note: null,
        client_note: null,
        job_type: [],
      },
      jobTypeList: [
        {
          text: null,
        },
      ],
    };
  },
  components: {
    // editor: TinyMCE,
  },
  methods: {
    createJobType() {
      this.jobTypeList.push({
        text: null,
      });
    },
    deleteJobType(index) {
      if (this.jobTypeList.length > 1) {
        this.jobTypeList.splice(index, 1);
      }
    },
    getJobSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job" })
        .then(({ data }) => {
          _this.setting = data;
          if (data.job_type) {
            _this.jobTypeList = data.job_type.map((row) => {
              return { text: row };
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateJobSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.setting.job_type = _this.jobTypeList.map((row) => {
        return row.text;
      });
      _this.$store
        .dispatch(PATCH, { url: "setting/job", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getJobSetting();
  },
};
</script>
