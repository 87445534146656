<template>
  <v-container fluid v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Service
        </h3>
      </v-flex>
      <div class="">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </v-layout>
    <v-row>
      <v-col md="10">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 75vh; position: relative"
        >
          <v-card flat class="custom-grey-border remove-border-radius mt-4">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Skills
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <div class="d-flex justify-content-end">
                      <v-btn
                        depressed
                        small
                        class="text-white"
                        color="cyan"
                        v-on:click="jobTypeDialogOpen('skill')"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                      >
                        <span class="font-size-16 font-weight-600">Create</span>
                      </v-btn>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <v-simple-table
                    class="simple-table"
                    fixed-header
                    style="min-height: 200px"
                    :height="paginatedSkills.length > 0 ? 'auto' : '100px'"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="simple-table-th" width="50">#</th>
                          <th class="simple-table-th" width="500">Skills</th>
                          <th class="simple-table-th" width="70">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="paginatedSkills.length">
                        <tr
                          v-for="(jobs, index) in paginatedSkills"
                          :key="index"
                          class="alternate-listing-row"
                        >
                          <td width="50" class="simple-table-td">
                            {{
                              (skillsCurrentPage - 1) * itemsPerPage + index + 1
                            }}
                          </td>
                          <td
                            width="100"
                            class="simple-table-td text-capitalize"
                          >
                            <template v-if="jobs?.text">
                              <v-chip
                                small
                                :color="jobs.color"
                                class="ml-1 text-white mt-n1"
                                label
                                style="width: 6px !important"
                              ></v-chip>
                              {{ jobs?.text }}
                            </template>
                            <em v-else class="text-muted"> no name </em>
                          </td>
                          <td class="simple-table-td">
                            <v-icon
                              color="cyan"
                              size="20"
                              @click="updateSkillService(jobs, 'skill')"
                              >mdi-pencil</v-icon
                            >
                            <v-icon
                              color="red"
                              class="ml-2"
                              size="20"
                              @click="deleteSkills(jobs, 'skill')"
                              >mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tr v-else>
                        <td colspan="3" class="py-4">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no skills at the moment.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row
                justify="end"
                class="align-center pagination_btn listing-footer px-2"
              >
                <p class="m-0 listing-footer-count">
                  Showing {{ (skillsCurrentPage - 1) * itemsPerPage + 1 }} to
                  {{
                    Math.min(
                      skillsCurrentPage * itemsPerPage,
                      serviceSkills.length
                    )
                  }}
                  of {{ serviceSkills.length }} entries
                </p>
                <v-spacer></v-spacer>
                <v-pagination
                  v-model="skillsCurrentPage"
                  :length="skillsPageCount"
                  total-visible="7"
                  color="cyan"
                  @input="handlePageChange('skills')"
                ></v-pagination>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card flat class="custom-grey-border remove-border-radius mt-4">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Certificates
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <div class="d-flex justify-content-end">
                      <v-btn
                        depressed
                        small
                        class="text-white"
                        color="cyan"
                        v-on:click="jobTypeDialogOpen('certificate')"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                      >
                        <span class="font-size-16 font-weight-600">Create</span>
                      </v-btn>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <v-simple-table
                    class="simple-table"
                    fixed-header
                    style="min-height: 200px"
                    :height="
                      paginatedCertificates.length > 0 ? 'auto' : '100px'
                    "
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="simple-table-th" width="50">#</th>
                          <th class="simple-table-th" width="500">
                            Certificate
                          </th>
                          <th class="simple-table-th" width="70">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="paginatedCertificates.length">
                        <tr
                          v-for="(jobs, index) in paginatedCertificates"
                          :key="index"
                          class="alternate-listing-row"
                        >
                          <td width="50" class="simple-table-td">
                            {{
                              (certificatesCurrentPage - 1) * itemsPerPage +
                              index +
                              1
                            }}
                          </td>
                          <td
                            width="100"
                            class="simple-table-td text-capitalize"
                          >
                            <template v-if="jobs?.text">
                              <v-chip
                                small
                                :color="jobs.color"
                                class="ml-1 text-white mt-n1"
                                label
                                style="width: 6px !important"
                              ></v-chip>
                              {{ jobs?.text }}
                            </template>
                            <em v-else class="text-muted"> no name </em>
                          </td>
                          <td class="simple-table-td">
                            <v-icon
                              color="cyan"
                              size="20"
                              @click="updateSkillService(jobs, 'certificate')"
                              >mdi-pencil</v-icon
                            >
                            <v-icon
                              color="red"
                              class="ml-2"
                              size="20"
                              @click="deleteSkills(jobs, 'certificate')"
                              >mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tr v-else>
                        <td colspan="3" class="py-4">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no certificates at the moment.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row
                justify="end"
                class="align-center pagination_btn listing-footer px-2"
              >
                <p class="m-0 listing-footer-count">
                  Showing
                  {{ (certificatesCurrentPage - 1) * itemsPerPage + 1 }} to
                  {{
                    Math.min(
                      certificatesCurrentPage * itemsPerPage,
                      serviceCertificate.length
                    )
                  }}
                  of {{ serviceCertificate.length }} entries
                </p>
                <v-spacer></v-spacer>
                <v-pagination
                  v-model="certificatesCurrentPage"
                  :length="certificatesPageCount"
                  total-visible="7"
                  color="cyan"
                  @input="handlePageChange('certificates')"
                ></v-pagination>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card flat class="custom-grey-border remove-border-radius mt-4">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    License
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <div class="d-flex justify-content-end">
                      <v-btn
                        depressed
                        small
                        class="text-white"
                        color="cyan"
                        v-on:click="jobTypeDialogOpen('license')"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                      >
                        <span class="font-size-16 font-weight-600">Create</span>
                      </v-btn>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <v-simple-table
                    class="simple-table"
                    fixed-header
                    style="min-height: 200px"
                    :height="paginatedLicenses.length > 0 ? 'auto' : '100px'"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="simple-table-th" width="50">#</th>
                          <th class="simple-table-th" width="500">License</th>
                          <th class="simple-table-th" width="70">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="paginatedLicenses.length">
                        <tr
                          v-for="(jobs, index) in paginatedLicenses"
                          :key="index"
                          class="alternate-listing-row"
                        >
                          <td width="50" class="simple-table-td">
                            {{
                              (licensesCurrentPage - 1) * itemsPerPage +
                              index +
                              1
                            }}
                          </td>
                          <td
                            width="100"
                            class="simple-table-td text-capitalize"
                          >
                            <template v-if="jobs?.text">
                              <v-chip
                                small
                                :color="jobs.color"
                                class="ml-1 text-white mt-n1"
                                label
                                style="width: 6px !important"
                              ></v-chip>
                              {{ jobs?.text }}
                            </template>
                            <em v-else class="text-muted"> no name </em>
                          </td>
                          <td class="simple-table-td">
                            <v-icon
                              color="cyan"
                              size="20"
                              @click="updateSkillService(jobs, 'license')"
                              >mdi-pencil</v-icon
                            >
                            <v-icon
                              color="red"
                              class="ml-2"
                              size="20"
                              @click="deleteSkills(jobs, 'license')"
                              >mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tr v-else>
                        <td colspan="3" class="py-4">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no licenses at the moment.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row
                justify="end"
                class="align-center pagination_btn listing-footer px-2"
              >
                <p class="m-0 listing-footer-count">
                  Showing {{ (licensesCurrentPage - 1) * itemsPerPage + 1 }} to
                  {{
                    Math.min(
                      licensesCurrentPage * itemsPerPage,
                      serviceLicense.length
                    )
                  }}
                  of {{ serviceLicense.length }} entries
                </p>
                <v-spacer></v-spacer>
                <v-pagination
                  v-model="licensesCurrentPage"
                  :length="licensesPageCount"
                  total-visible="7"
                  color="cyan"
                  @input="handlePageChange('licenses')"
                ></v-pagination>
              </v-row>
            </v-card-text>
          </v-card>
        </perfect-scrollbar>
      </v-col>
    </v-row>
    <Dialog :commonDialog="skillDialog" :dialog-width="850">
      <template v-slot:title>
        Create {{ related_type }}
        <!--   <template v-if="holidayData.id">Update </template>
          <template v-else>Create </template>Holiday -->
      </template>
      <template v-slot:body>
        <v-container fluid>
          <v-form
            ref="ticketCollecttionFormSkills"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateSkills"
          >
            <v-row>
              <v-col md="12">
                <label class="my-auto fw-500 required mb-1">Title</label>
                <TextField
                  id="quotation-title"
                  dense
                  filled
                  placeholder="Name"
                  solo
                  flat
                  counter="50"
                  v-model="jobSkillsPayload.text"
                  color="cyan"
                  :maxlength="250"
                  :rules="[
                    validateRules.required(jobSkillsPayload.text, 'Title'),
                  ]"
                />
              </v-col>
              <v-col md="12">
                <label class="my-auto fw-500 required mb-1">Color</label>
                <v-color-picker
                  elevation="10"
                  v-model.trim="jobSkillsPayload.color"
                  :swatches="swatches"
                  show-swatches
                  hide-canvas
                  hide-inputs
                  swatches-max-height="100"
                ></v-color-picker>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :loading="dataLoading"
          :disabled="dataLoading || !formValid"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="updateAllSetting(is_exist)"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="skillDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <DeleteDialog :delete-dialog="deleteSkillDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-18 font-weight-600 delete-text">
          Are you sure you want to delete this
          <span class="text-capitalize">{{ related_type }}</span
          >?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="deleteJobLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteSkillDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="deleteJobLoading"
          :loading="deleteJobLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteJobTypeConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <Dialog :common-dialog="confirm_dialog" :dialog-width="850">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
          <v-container class="px-10" fluid>
            <h3 class="font-weight-500 text-center">
              <span class="text-capitalize">{{ related_type }}</span> already
              exists in another transaction. Any updates will be reflected in a
              new transaction.
            </h3>
          </v-container>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirm_loading"
          v-on:click="confirm_dialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="updateSkills(related_type)"
          :disabled="!formValid || confirm_loading"
          :loading="confirm_loading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";

/*   import Color from "@/view/pages/engineer/detail/Color"; */

export default {
  mixins: [ValidationMixin, CommonMixin],
  name: "profile-service-setting",
  data() {
    return {
      itemsPerPage: 10,
      skillsCurrentPage: 1,
      certificatesCurrentPage: 1,
      licensesCurrentPage: 1,
      summaryCerts: {
        skill: {
          active: 0,
          inactive: 0,
        },
        certificate: {
          active: 0,
          inactive: 0,
        },
        license: {
          active: 0,
          inactive: 0,
        },
      },
      formValid: true,
      skillDialog: false,
      deleteJobLoading: false,
      related_id: null,
      is_exist: 0,
      related_type: null,
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      jobSkillsPayload: {
        id: null,

        text: null,
        is_active: 1,
        key_color: null,
        color: {
          alpha: 1,
          hex: "#00BCD4",
          hexa: "#00BCD4FF",
          hsla: {
            a: 1,
            h: 186.79245283018867,
            l: 0.41568627450980394,
            s: 1,
          },
          hsva: {
            a: 1,
            h: 186.79245283018867,
            s: 1,
            v: 0.8313725490196079,
          },
          hue: 186.79245283018867,
          rgba: {
            a: 1,
            b: 212,
            g: 188,
            r: 0,
          },
        },
      },
      deleteSkillDialog: false,
      confirm_dialog: false,
      confirm_loading: false,
      serviceSkills: [],
      serviceCertificate: [],
      serviceLicense: [],
      show_skills: true,
      show_licence: true,
      dataLoading: false,
      pageLoading: true,
    };
  },
  methods: {
    jobTypeDialogOpen(type) {
      if (type == "skill") {
        this.related_type = "Skill";
      } else if (type == "certificate") {
        this.related_type = "Certificate";
      } else if (type == "license") {
        this.related_type = "License";
      }
      this.jobSkillsPayload = {
        id: null,
        color: null,
        is_active: 1,
        text: null,
      };
      this.skillDialog = true;
    },
    updateSkillService(param, type) {
      if (type == "skill") {
        this.related_type = "Skill";
      } else if (type == "certificate") {
        this.related_type = "Certificate";
      } else if (type == "license") {
        this.related_type = "License";
      }
      const _this = this;
      _this.skillDialog = true;
      _this.is_exist = param.is_exist ? 1 : 0;
      setTimeout(function () {
        _this.jobSkillsPayload = {
          id: _this.lodash.toSafeInteger(param.id),
          color: param.color ? param.color : null,
          is_active: param.is_active ? param.is_active : 1,
          text: param.text ? param.text : null,
        };
      }, 500);
      this.getServiceSetting();
    },
    deleteSkills(data, type) {
      this.related_id = data.id;
      this.is_exist = data.is_exist ? 1 : 0;
      this.related_type = type;
      this.deleteSkillDialog = true;
    },
    deleteJobTypeConfirm() {
      const _this = this;
      _this.deleteJobLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: `services/delete/${this.related_type}/${this.related_id}`,
          data: {
            is_exist: this.is_exist,
          },
        })
        .then(() => {
          _this.deleteJobLoading = false;
          this.deleteSkillDialog = false;
          this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
          _this.deleteJobLoading = false;
        });
    },
    updateAllSetting(type) {
      if (type == 0) {
        this.updateSkills(this.related_type);
      } else {
        this.confirm_dialog = true;
      }
    },
    updateSkills(type) {
      const _this = this;
      this.$refs.ticketCollecttionFormSkills.validate();
      const formErrors = _this.validateForm(
        _this.$refs.ticketCollecttionFormSkills
      );

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          _this.dataLoading = false;
        }
        return false;
      }
      if (this.jobSkillsPayload?.color?.hexa) {
        let colorCode = this.jobSkillsPayload?.color?.hexa
          ? this.jobSkillsPayload?.color?.hexa
          : null;
        this.jobSkillsPayload.color = colorCode;
      }
      _this.dataLoading = true;
      let URL = "";
      let typeFormated = type.toLowerCase();
      if (typeFormated == "skill") {
        URL = "skills";
      } else if (typeFormated == "certificate") {
        URL = "setting/service-skills";
      } else if (typeFormated == "license") {
        URL = "setting/service-licence";
      }
      _this.$store
        .dispatch(PATCH, {
          url: URL,
          data: _this.jobSkillsPayload,
        })
        .then(() => {
          this.skillDialog = false;
          _this.confirm_dialog = false;
          _this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
          _this.dataLoading = false;
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },

    getServiceSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/service" })
        .then(({ data }) => {
          console.log(data);
          _this.setting = data;
          if (Array.isArray(data.skills)) {
            _this.serviceSkills = data?.skills;
          }
          if (Array.isArray(data.licenseCertificate)) {
            _this.serviceLicense = data?.licenseCertificate;
          }
          if (Array.isArray(data.skillCertificate)) {
            _this.serviceCertificate = data?.skillCertificate;
          }
          _this.show_skills = data.show_skills;
          _this.show_licence = data.show_licence;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    handlePageChange(type) {
      if (type === "skills") {
        this.skillsCurrentPage = 1;
      } else if (type === "certificates") {
        this.certificatesCurrentPage = 1;
      } else if (type === "licenses") {
        this.licensesCurrentPage = 1;
      }
    },
  },

  mounted() {
    this.getServiceSetting();
    /*   this.$refs.ticketCollecttionFormSkills.validate(); */
    /*   this.$refs.ticketCollecttionFormNew.validate(); */
  },
  computed: {
    paginatedSkills() {
      const start = (this.skillsCurrentPage - 1) * this.itemsPerPage;
      const end = this.skillsCurrentPage * this.itemsPerPage;
      return this.serviceSkills.slice(start, end);
    },
    skillsPageCount() {
      return Math.ceil(this.serviceSkills.length / this.itemsPerPage);
    },
    paginatedCertificates() {
      const start = (this.certificatesCurrentPage - 1) * this.itemsPerPage;
      const end = this.certificatesCurrentPage * this.itemsPerPage;
      return this.serviceCertificate.slice(start, end);
    },
    certificatesPageCount() {
      return Math.ceil(this.serviceCertificate.length / this.itemsPerPage);
    },
    paginatedLicenses() {
      const start = (this.licensesCurrentPage - 1) * this.itemsPerPage;
      const end = this.licensesCurrentPage * this.itemsPerPage;
      return this.serviceLicense.slice(start, end);
    },
    licensesPageCount() {
      return Math.ceil(this.serviceLicense.length / this.itemsPerPage);
    },
  },
  components: {
    Dialog,
    DeleteDialog,
  },
};
</script>
