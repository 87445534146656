<template>
  <v-card
    flat
    class="custom-setting-height custom-grey-border remove-border-radius mt-4"
  >
    <v-card-title class="headline grey lighten-4">
      <v-row>
        <v-col md="6" class="py-0 my-auto">
          <div class="d-flex">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Invoice Locking system
            </h3>
            <v-select
              style="max-width: 200px"
              v-model="year"
              :items="yearList"
              placeholder="Year"
              solo
              flat
              dense
              filled
              hide-details
              item-color="cyan"
              :disabled="pageLoading"
              :loading="pageLoading"
              color="cyan"
              class="px-4"
              @change="getInvoiceLockMonths()"
            ></v-select>
          </div>
        </v-col>
        <template v-if="getPermission('setting:update')">
          <v-col md="6" class="text-right py-0">
            <v-btn
              depressed
              small
              class="text-white"
              color="cyan"
              :disabled="pageLoading"
              :loading="pageLoading"
              @click="updateInvoiceLockSetting()"
            >
              <span class="font-size-16 font-weight-600">Update</span>
            </v-btn>
          </v-col>
        </template>
      </v-row>
    </v-card-title>
    <v-card-text
      class="font-size-16 pa-0"
      :class="{
        'pointer-events-none': !getPermission('setting:update'),
      }"
    >
      <v-row>
        <v-col md="6" class="pb-4" style="background-color: antiquewhite">
          <table class="width-100 mt-0">
            <tbody>
              <tr v-for="(row, index) in invoicelockingMonths" :key="index">
                <td
                  class="font-weight-600 font-size-16 text-capitalize"
                  width="50"
                >
                  {{ row.month }}
                </td>
                <td>
                  <DatePicker
                    class="pr-0"
                    hide-details
                    hide-top-margin
                    :loading="pageLoading"
                    placeholder="Date"
                    v-model="row.max_date"
                  >
                  </DatePicker>
                </td>
                <td width="50">
                  <v-checkbox
                    v-model="row.activated"
                    class="mt-0"
                    inset
                    color="cyan"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DatePicker from "@/view/components/DatePicker";
import moment from "moment-timezone";
import { QUERY, PATCH } from "@/core/services/store/request.module";

export default {
  name: "invoice-locking",

  data() {
    return {
      pageLoading: false,
      defaultlockingMonths: [
        {
          month: "january",
          activated: false,
          max_date: moment().month(0).endOf("month").format("YYYY-MM-DD"),
        },
        {
          month: "february",
          activated: false,
          max_date: moment().month(1).endOf("month").format("YYYY-MM-DD"),
        },
        {
          month: "march",
          activated: false,
          max_date: moment().month(2).endOf("month").format("YYYY-MM-DD"),
        },
        {
          month: "april",
          activated: false,
          max_date: moment().month(3).endOf("month").format("YYYY-MM-DD"),
        },
        {
          month: "may",
          activated: false,
          max_date: moment().month(4).endOf("month").format("YYYY-MM-DD"),
        },
        {
          month: "june",
          activated: false,
          max_date: moment().month(5).endOf("month").format("YYYY-MM-DD"),
        },
        {
          month: "july",
          activated: false,
          max_date: moment().month(6).endOf("month").format("YYYY-MM-DD"),
        },
        {
          month: "august",
          activated: false,
          max_date: moment().month(7).endOf("month").format("YYYY-MM-DD"),
        },
        {
          month: "september",
          activated: false,
          max_date: moment().month(8).endOf("month").format("YYYY-MM-DD"),
        },
        {
          month: "october",
          activated: false,
          max_date: moment().month(9).endOf("month").format("YYYY-MM-DD"),
        },
        {
          month: "november",
          activated: false,
          max_date: moment().month(10).endOf("month").format("YYYY-MM-DD"),
        },
        {
          month: "december",
          activated: false,
          max_date: moment().month(11).endOf("month").format("YYYY-MM-DD"),
        },
      ],
      defaultYear: moment().format("YYYY"),
      invoicelockingMonths: [],
      year: moment().format("YYYY"),
      yearList: [
        {
          value: moment().subtract(2, "year").format("YYYY"),
          text: moment().subtract(2, "year").format("YYYY"),
        },
        {
          value: moment().subtract(1, "year").format("YYYY"),
          text: moment().subtract(1, "year").format("YYYY"),
        },
        {
          value: moment().format("YYYY"),
          text: moment().format("YYYY"),
        },
        {
          value: moment().add(1, "year").format("YYYY"),
          text: moment().add(1, "year").format("YYYY"),
        },
        {
          value: moment().add(2, "year").format("YYYY"),
          text: moment().add(2, "year").format("YYYY"),
        },
      ],
    };
  },
  components: {
    DatePicker,
  },
  methods: {
    updateInvoiceLockSetting() {
      const _this = this;
      _this.pageLoading = true;
      let request = {
        year: this.year,
        months: this.invoicelockingMonths,
      };
      _this.$store
        .dispatch(PATCH, { url: "setting/invoice-locking", data: request })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getInvoiceLockMonths();
          _this.pageLoading = false;
        });
    },
    getInvoiceLockMonths() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "setting/invoice-locking",
          data: {
            year: this.year,
          },
        })
        .then(({ data }) => {
          if (data?.year) {
            this.year = data?.year;
          } else {
            this.year = this.defaultYear;
          }
          if (data?.month?.length > 0) {
            this.invoicelockingMonths = data?.month;
          } else {
            this.invoicelockingMonths = this.defaultlockingMonths;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getInvoiceLockMonths();
  },
};
</script>
