<template>
  <v-container fluid class="create-custom-field">
    <v-form
      ref="custom-field-form"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="createOrUpdate"
    >
      <v-row>
        <v-col md="2">
          <label for="" class="v-label mt-2 required">Label Name</label>
        </v-col>
        <v-col md="6">
          <TextField
            dense
            filled
            solo
            flat
            counter="35"
            placeholder="Label Name"
            hide-details
            color="cyan"
            :loading="pageLoading"
            :disabled="pageLoading"
            v-model="form.label"
            :rules="[
              validateRules.required(form.label, 'Label Name'),
              validateRules.minLength(form.label, 'Label Name', 2),
              validateRules.maxLength(form.label, 'Label Name', 35),
            ]"
          />
          <div class="mt-2">
            <v-checkbox
              v-model="form.is_mandatory"
              :loading="pageLoading"
              :disabled="pageLoading"
              class="ma-0 pa-0"
              color="red"
              label="Is Mandatory"
            ></v-checkbox>
          </div>
          <template
            v-if="
              form.field_type &&
              !(
                form.field_type == 'attachment' ||
                form.field_type == 'radio' ||
                form.field_type == 'checkbox' ||
                form.field_type == 'textarea'
              )
            "
          >
            <div class="mt-2">
              <v-checkbox
                v-model="form.is_filter"
                :loading="pageLoading"
                :disabled="pageLoading"
                class="ma-0 pa-0"
                color="cyan"
                label="Is used for filter"
              ></v-checkbox>
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <label for="" class="v-label mt-2 required">Data Type</label>
        </v-col>
        <v-col md="6">
          <v-select
            dense
            filled
            solo
            flat
            :items="gFieldTypes"
            placeholder="Data Types"
            hide-details
            color="cyan"
            :loading="pageLoading"
            :disabled="pageLoading"
            v-model="form.field_type"
            :rules="[validateRules.required(form.field_type, 'Data Types')]"
            @change="setDefaultProperties"
          />
          <template
            v-if="form.field_type == 'text' || form.field_type == 'textarea'"
          >
            <div class="mt-2" style="width: 200px">
              <label class="v-label mt-2">Max Character Limit</label>
              <TextField
                dense
                filled
                solo
                flat
                placeholder="Max Character Limit"
                hide-details
                color="cyan"
                :loading="pageLoading"
                v-model="form.max"
                @keypress="($event) => isNumber($event)"
              />
            </div>
          </template>
          <template v-if="form.field_type == 'number'">
            <div class="mt-2">
              <v-checkbox
                :loading="pageLoading"
                v-model="form.decimal_allow"
                class="ma-0 pa-0"
                color="cyan"
                label="Decimal Number Allowed"
              ></v-checkbox>
            </div>
            <div class="d-flex" style="gap: 50px">
              <div style="width: 50%" class="py-1">
                <label class="v-label mt-2">Min Digit</label>
                <TextField
                  dense
                  filled
                  solo
                  flat
                  placeholder="Min Length"
                  hide-details
                  color="cyan"
                  :loading="pageLoading"
                  v-model="form.min"
                  @keypress="($event) => isNumber($event)"
                />
              </div>
              <div style="width: 50%" class="py-1">
                <label class="v-label mt-2">Max Digit</label>
                <TextField
                  dense
                  filled
                  solo
                  flat
                  placeholder="Max Length"
                  hide-details
                  color="cyan"
                  :loading="pageLoading"
                  v-model="form.max"
                  @keypress="($event) => isNumber($event)"
                />
              </div>
            </div>
          </template>
        </v-col>
      </v-row>
      <template v-if="form.field_type != 'attachment'">
        <v-row>
          <v-col md="2" class="my-auto">
            <label for="" class="v-label">Placeholder</label>
          </v-col>
          <v-col md="6">
            <TextField
              dense
              filled
              solo
              flat
              counter="35"
              placeholder="Placeholder"
              hide-details
              color="cyan"
              :loading="pageLoading"
              :disabled="pageLoading"
              v-model="form.placeholder"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2">
            <label for="" class="v-label mt-2">Instructions</label>
          </v-col>
          <v-col md="6">
            <TextField
              dense
              filled
              solo
              flat
              counter="100"
              placeholder="Instructions"
              hide-details
              color="cyan"
              :loading="pageLoading"
              :disabled="pageLoading"
              v-model="form.instructions"
            />
            <span class="default-font-small"
              >Enter some text to help users understand the purpose of this
              custom field.</span
            >
          </v-col>
        </v-row>
      </template>
      <template v-if="form.field_type == 'attachment'">
        <v-row>
          <v-col md="2">
            <label for="" class="v-label mt-2">File Types</label>
          </v-col>
          <v-col md="6">
            <div class="d-flex flex-wrap">
              <div
                v-for="(item, index) in fileTypes"
                :key="item.value + index"
                style="width: 50%"
                class="py-1"
              >
                <v-checkbox
                  :disabled="
                    (form.valid_file.length == 1 &&
                    form.valid_file[0] == item.value
                      ? true
                      : false) || pageLoading
                  "
                  class="mr-5 pa-0"
                  v-model="form.valid_file"
                  :label="item.text"
                  :value="item.value"
                ></v-checkbox>
              </div>
            </div>
            <div class="d-flex" style="gap: 50px">
              <div style="width: 50%" class="py-1">
                <label class="v-label mt-2">Max Upload Size</label>
                <v-select
                  dense
                  filled
                  solo
                  flat
                  :items="[
                    { text: '2MB', value: 2 },
                    { text: '3MB', value: 3 },
                    { text: '4MB', value: 4 },
                    { text: '5MB', value: 5 },
                    { text: '6MB', value: 6 },
                    { text: '7MB', value: 7 },
                    { text: '8MB', value: 8 },
                    { text: '9MB', value: 9 },
                    { text: '10MB', value: 10 },
                  ]"
                  placeholder="Max Upload Size"
                  hide-details
                  color="cyan"
                  :loading="pageLoading"
                  :disabled="pageLoading"
                  v-model="form.max_upload_size"
                  :rules="[
                    validateRules.required(
                      form.max_upload_size,
                      'Max Upload Size'
                    ),
                  ]"
                />
              </div>
              <div style="width: 50%" class="py-1">
                <label class="v-label mt-2">Max Upload Limit</label>
                <v-select
                  dense
                  filled
                  solo
                  flat
                  :items="[
                    { text: '1 File', value: 1 },
                    { text: '2 File', value: 2 },
                    { text: '3 File', value: 3 },
                    { text: '4 File', value: 4 },
                    { text: '5 File', value: 5 },
                    { text: '6 File', value: 6 },
                    { text: '7 File', value: 7 },
                    { text: '8 File', value: 8 },
                    { text: '9 File', value: 9 },
                    { text: '10 File', value: 10 },
                  ]"
                  placeholder="Max Upload Limit"
                  hide-details
                  color="cyan"
                  :loading="pageLoading"
                  :disabled="pageLoading"
                  v-model="form.max_upload_limit"
                  :rules="[
                    validateRules.required(
                      form.max_upload_limit,
                      'Max Upload Limit'
                    ),
                  ]"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-if="isSelection">
        <v-row>
          <v-col cols="12" class="border">
            <v-layout class="mb-2">
              <div>
                <h4>Options</h4>
              </div>
            </v-layout>
            <CreateOptions
              :loading="pageLoading"
              :disabled="pageLoading"
              v-model="form.options"
            />
          </v-col>
        </v-row>
      </template>
      <template v-if="form.field_type != 'attachment'">
        <v-row>
          <v-col
            md="12"
            v-if="form.field_type == 'checkbox' || form.field_type == 'radio'"
          >
            <div class="mt-2 d-flex align-center">
              <v-checkbox
                v-model="form.row"
                :disabled="pageLoading"
                class="ma-0 py-3 mr-5"
                color="cyan"
                label="Show in one row"
              ></v-checkbox>
              <div style="width: 120px">
                <v-select
                  v-if="form.row"
                  dense
                  filled
                  solo
                  flat
                  :items="[
                    { text: '2 Items', value: 6 },
                    { text: '3 Items', value: 4 },
                  ]"
                  placeholder="Items"
                  hide-details
                  color="cyan"
                  :loading="pageLoading"
                  :disabled="pageLoading"
                  v-model="form.row_items"
                  :rules="[validateRules.required(form.row_items, 'Row Items')]"
                />
              </div>
            </div>
          </v-col>
          <v-col md="2" class="mt-2">
            <label for="" class="v-label">Default Value</label>
          </v-col>
          <v-col md="6">
            <InputField
              dense
              filled
              solo
              flat
              :loading="pageLoading"
              :disabled="pageLoading"
              v-model="form.initialVal"
              :field-type="form.field_type"
              placeholder="Default Value"
              :items="form.options"
              :row="form.row"
              :row-items="form.row_items"
              :max="form.max"
              :min="form.min"
              :decimal-allow="form.decimal_allow"
              :max-upload-size="form.max_upload_size"
              :max-upload-limit="form.max_upload_limit"
              hide-details
              color="cyan"
              :class="{ required: form.is_mandatory }"
            />
          </v-col>
        </v-row>
      </template>
      <v-row>
        <v-col md="2">
          <label for="" class="v-label mt-2">Column</label>
        </v-col>
        <v-col md="4">
          <v-select
            dense
            filled
            solo
            flat
            :items="[
              {
                text: 'Full width (width:100%)',
                value: '12',
              },
              {
                text: 'Two Column (width:50%)',
                value: '6',
              },
              {
                text: 'Three Column (width:33%)',
                value: '4',
              },
            ]"
            placeholder="Column"
            hide-details
            color="cyan"
            :loading="pageLoading"
            :disabled="pageLoading"
            v-model="form.column"
          />
          <div class="mt-3">
            <v-checkbox
              v-model="form.hide_label"
              :disabled="pageLoading"
              class="ma-0 pa-0"
              color="cyan"
              label="Hide Label"
            ></v-checkbox>
          </div>
          <div class="mt-3">
            <v-checkbox
              v-model="form.start_with_new_line"
              :disabled="pageLoading"
              class="ma-0 pa-0"
              color="cyan"
              label="Start with new line"
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <div class="field-preview mt-5">
      <h3>Preview</h3>
      <v-row>
        <v-col :cols="form.column">
          <label
            v-if="form.label && !form.hide_label"
            class="v-label mb-2"
            :class="{ required: form.is_mandatory }"
            >{{ form.label }}</label
          >
          <InputField
            dense
            filled
            solo
            flat
            disabled
            v-model="form.initialVal"
            :field-type="form.field_type"
            :placeholder="form.placeholder"
            :items="form.options"
            :row="form.row"
            :row-items="form.row_items"
            :max="form.max"
            :min="form.min"
            :decimal-allow="form.decimal_allow"
            :max-upload-size="form.max_upload_size"
            :max-upload-limit="form.max_upload_limit"
            :valid-file="form.valid_file"
            hide-details
            color="cyan"
            :class="{ required: form.is_mandatory }"
          />
        </v-col>
        <v-col md="8">
          <!-- {{ form.initialVal }} -->
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import CreateOptions from "./field-properties/CreateOptions.vue";
import InputField from "@/view/pages/custom-field-v2/field-properties/c-input-filed.vue";
import { POST, GET } from "@/core/services/store/request.module";
import {
  ErrorEventBus,
  SuccessEventBus,
  InitializeError,
} from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  name: "CreateCustomFieldV2",
  mixins: [ValidationMixin],
  props: {
    updateId: {
      type: [String, Number],
      default: null,
    },
    module: {
      type: String,
      default: null,
    },
    order: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      formValid: true,
      pageLoading: false,
      form: {
        id: 0,
        label: null,
        field_type: null,
        instructions: null,
        initialVal: "",
        placeholder: null,
        is_mandatory: false,
        is_filter: false,

        decimal_allow: true,
        hide_label: false,
        max_upload_size: 5,
        max_upload_limit: 5,
        auto_fill_current_date: false,
        row: false,
        row_items: 6,
        order: this.order,
        min: 0,
        max: 100,
        column: "12",
        start_with_new_line: false,
        options: [
          {
            order: 1,
            value: "Options-1",
          },
          {
            order: 2,
            value: "Options-2",
          },
        ],
        valid_file: [],
      },
      fileTypes: [
        {
          text: "Image",
          value: "jpeg,jpg,png,gif",
        },
        {
          text: "PDF",
          value: "pdf",
        },
        {
          text: "Docs",
          value: "docx,doc",
        },
        {
          text: "Excel",
          value: "xls,xlsx",
        },
      ],
    };
  },
  methods: {
    setDefaultProperties() {
      const _default = {
        placeholder: "",
        column: "12",
        row: false,
        min: 0,
        max: 100,
        max_upload_size: 5,
        max_upload_limit: 5,
        decimal_allow: true,
        hide_label: false,
        is_filter: false,
        instructions: null,
        row_items: 6,
        initialVal: "",
        options: [
          {
            order: 1,
            value: "Options-1",
          },
          {
            order: 2,
            value: "Options-2",
          },
        ],
      };
      switch (this.form.field_type) {
        case "checkbox":
          _default.initialVal = [];
          break;
        case "date":
          _default.initialVal = ""; //new Date();
          break;
        case "date-time":
          _default.initialVal = ""; //new Date();
          break;
        case "text":
          _default.max = 100;
          break;
        case "textarea":
          _default.max = 200;
          break;
        case "number":
          _default.max = 20;
          break;
        case "attachment":
          _default.initialVal = [];
          _default.valid_file = [
            "jpeg,jpg,png,gif",
            "pdf",
            "docx,doc",
            "xls,xlsx",
          ];
          break;
      }
      this.form = { ...this.form, ..._default };
    },
    createOrUpdate() {
      this.$refs["custom-field-form"].validate();
      const formErrors = this.validateForm(this.$refs["custom-field-form"]);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }
      const payload = {
        custom_fields: [
          {
            module_type: this.getModule,
            value: [{ ...this.form }],
          },
        ],
      };
      const _this = this;
      _this.$emit("loading", true);
      _this.pageLoading = true;
      _this.$store
        .dispatch(POST, { url: "module/custom-fields", data: payload })
        .then(() => {
          let message = this.getUpdateId
            ? "Custom field updated successfully."
            : "Custom field created successfully.";
          SuccessEventBus.$emit("update:success", message);
          this.$emit("success", true);
        })
        .catch((error) => {
          console.log(error);
          this.$emit("error", true);
        })
        .finally(() => {
          this.$emit("loading", false);
          _this.pageLoading = false;
        });
    },
    getField() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, { url: `module/customField/${this.getUpdateId}` })
        .then(({ data }) => {
          this.form = {
            id: data.id,
            label: data.label,
            field_type: data.field_type,
            instructions: data.instructions,
            initialVal: data.initialVal,
            placeholder: data.placeholder,
            is_mandatory: data.is_mandatory,
            is_filter: data.is_filter,
            decimal_allow: data.decimal_allow,
            hide_label: data.hide_label,
            max_upload_size: Number(data.max_upload_size),
            max_upload_limit: Number(data.max_upload_limit),
            auto_fill_current_date: data.auto_fill_current_date,
            row: data.row,
            row_items: data.row_items ? Number(data.row_items) : 0,
            min: data.min ? Number(data.min) : 0,
            max: data.max ? Number(data.max) : 0,
            order: data.order ? Number(data.order) : 0,
            column: data.column,
            start_with_new_line: data.start_with_new_line,
            options: data.options,
            valid_file: data.valid_file,
          };
          /* console.log({res:data}); */
        })
        .catch((error) => {
          console.log(error);
          _this.$emit("error", true);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["gFieldTypes"]),
    isSelection() {
      let isOptions = false;
      switch (this.form.field_type) {
        case "checkbox":
          isOptions = true;
          break;
        case "radio":
          isOptions = true;
          break;
        case "dropdown":
          isOptions = true;
          break;
      }
      return isOptions;
    },
    getModule() {
      return this.module;
    },
    getUpdateId() {
      return this.updateId;
    },
  },
  components: {
    CreateOptions,
    InputField,
  },
  mounted() {
    if (this.getUpdateId) {
      this.getField();
    }
  },
};
</script>
<style lang="scss" scoped>
.v-input {
  padding-left: 0 !important;
}
</style>
