<template>
  <v-menu
    offset-y
    min-width="100px"
    style="padding: 0px 4px"
    rounded
    bottom
    v-if="textinput"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-chip
          class="ml-1 mb-1 pl-2 pr-1 text-uppercase"
          :color="`${color} white--text`"
          v-bind="{ ...$attrs }"
        >
          {{ textinput }}
          <v-icon style="font-size: 22px" small>mdi-menu-down</v-icon>
        </v-chip>
      </div>
    </template>

    <v-list elevation>
      <v-list-item
        class="list-item"
        v-for="(item, index) in items"
        :key="index"
        @click="updateStatus(item)"
      >
        <v-list-item-title>
          <v-chip
            class="pl-2 pr-1 text-uppercase cursor-pointer"
            :color="`${item.status_color} white--text`"
            v-bind="{ ...$attrs }"
          >
            {{ item.text }}
            <!-- <v-icon style="font-size: 22px" small>mdi-menu-down</v-icon> -->
          </v-chip>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "status-input-field",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    dense: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textinput: null,
      color: "orange",
    };
  },
  watch: {
    value() {
      this.textinput = this.value;
      this.setDefault();
    },
    textinput() {
      this.$emit("input", this.textinput);
    },
    items() {
      this.setDefault();
    },
  },
  methods: {
    updateStatus(status) {
      if (status.value != this.textinput) {
        this.textinput = status.value;
        this.color = status.status_color;
        this.$emit("input", this.textinput);
        this.$emit("change", this.textinput);
      }
    },
    setDefault() {
      this.$nextTick(() => {
        this.textinput = this.value;
        const _status = this.items.find((row) => row.value == this.value);
        if (_status) {
          this.color = _status.status_color;
          //this.$emit("change", this.textinput);
        }
      });
    },
  },
  mounted() {
    this.setDefault();
  },
};
</script>
