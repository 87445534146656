<template>
  <v-container fluid v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Role &amp; Permission
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col md="10">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="position: relative"
        >
          <v-card
            flat
            class="custom-setting-height custom-grey-border remove-border-radius"
          >
            <v-card-title class="headline sticky-header grey lighten-4 py-2">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Roles
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0 my-auto">
                    <v-btn
                      depressed
                      small
                      class="text-white"
                      color="cyan"
                      v-on:click="$router.push(getDefaultRoute('role.create'))"
                    >
                      <span class="font-size-16 font-weight-600">Create</span>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="custom-listing-table">
                <v-simple-table
                  class="simple-table"
                  fixed-header
                  :height="roles.length > 0 ? 'auto' : '100px'"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="simple-table-th" width="50">#</th>
                        <th class="simple-table-th" width="200">Role name</th>
                        <th class="simple-table-th" width="200">Users</th>
                        <th class="simple-table-th"></th>
                        <th class="simple-table-th" v-if="false" width="200">
                          From Amount
                        </th>
                        <th class="simple-table-th" v-if="false" width="200">
                          To Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="roles.length > 0">
                      <tr v-for="(row, index) in roles" :key="index">
                        <td width="50" class="simple-table-td">
                          {{ index + 1 }}.
                        </td>
                        <td width="200" class="simple-table-td text-uppercase">
                          {{ row.name }}
                        </td>
                        <td width="200" class="simple-table-td">
                          {{ row.user_count }}
                        </td>
                        <td class="simple-table-td">
                          <template v-if="getPermission('setting:update')">
                            <v-btn-toggle dense v-model="role_action[index]">
                              <v-btn
                                small
                                v-on:click="
                                  $router.push(
                                    getDefaultRoute('role.update', {
                                      params: {
                                        id: row.id,
                                      },
                                    })
                                  )
                                "
                              >
                                <span class="font-size-12 font-weight-600"
                                  >Edit</span
                                >
                              </v-btn>
                              <v-btn
                                small
                                v-if="false"
                                v-on:click="
                                  $router.push(
                                    getDefaultRoute('role.create', {
                                      query: {
                                        clone: row.id,
                                      },
                                    })
                                  )
                                "
                              >
                                <span class="font-size-12 font-weight-600"
                                  >Clone</span
                                >
                              </v-btn>
                              <v-btn
                                small
                                v-if="
                                  !row.super_admin &&
                                  !(row.id == 2 || row.id == 3 || row.id == 4)
                                "
                                v-on:click="deleteConfirmation(row.id)"
                              >
                                <v-icon small>mdi-delete</v-icon>
                              </v-btn>
                            </v-btn-toggle>
                          </template>
                        </td>
                        <td
                          width="200"
                          v-if="false"
                          class="simple-table-td text-uppercase"
                        >
                          {{ row.from_amount }}
                        </td>
                        <td
                          width="200"
                          v-if="false"
                          class="simple-table-td text-uppercase"
                        >
                          {{ row.to_amount }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-else>
                      <tr v-if="roleLoading">
                        <td
                          class="simple-table-td"
                          v-for="(di, did) in 4"
                          :key="did"
                        >
                          <v-skeleton-loader
                            type="text"
                            class="custom-skeleton"
                          ></v-skeleton-loader>
                        </td>
                      </tr>
                      <tr v-else>
                        <td colspan="4" class="simple-table-td">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no role at the moment.
                          </p>
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </div>
            </v-card-text>
          </v-card>
        </perfect-scrollbar>
      </v-col>
    </v-row>
    <RoleDelete
      :deleteDialog="roleDeleteDialog"
      :requestUrl="roleDeleteRequestURL"
      v-on:delete:success="getRoles"
      v-on:delete:close="roleDeleteDialog = false"
    ></RoleDelete>
  </v-container>
</template>

<script>
import { GET } from "@/core/services/store/request.module";
import RoleDelete from "@/view/pages/partials/Delete-Request-Template.vue";

export default {
  data() {
    return {
      role_action: [],
      roleLoading: false,
      roleDeleteDialog: false,
      roleDeleteRequestURL: "",
      roles: [],
    };
  },
  components: {
    RoleDelete,
  },
  methods: {
    deleteConfirmation(id) {
      this.roleDeleteRequestURL = "role/" + id;
      this.roleDeleteDialog = true;
    },
    getRoles() {
      const _this = this;
      _this.roleLoading = true;
      _this.$store
        .dispatch(GET, { url: "role" })
        .then(({ data }) => {
          _this.roles = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.roleLoading = false;
          _this.roleDeleteDialog = false;
          _this.roleDeleteRequestURL = "";
        });
    },
  },
  mounted() {
    this.getRoles();
  },
};
</script>
